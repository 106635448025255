html {
  overflow-y: initial !important;
  overflow-x: auto;
}

.siteWrap {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  overflow-y: scroll !important;
}

tr:nth-of-type(odd) {
  background: #edeaea;
}

tr:nth-of-type(even) {
  background: #d3d3d3;
}

.list li:nth-of-type(odd) {
  background: #edeaea;
}

.list li:nth-of-type(even) {
  background: #d3d3d3;
}

.secondary-list li:nth-of-type(odd) {
  background: #d3d3d3;
}

.secondary-list li:nth-of-type(even) {
  background: #edeaea;
}

.list {
  padding: 0px;
}

.list li,
.secondary-list li {
  padding: 5px;
}

#current-coverage li {
  margin-bottom: 10px;
}

.no-pad {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

td {
  padding: 10px;
}

.large-checkbox {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.button {
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: rgb(2 132 199);
  color: white;
}

.button:hover {
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: rgb(56 189 248);
  color: white;
}

.button-red {
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: rgb(127 29 29);
  color: white;
}

.button-red:hover {
  border-radius: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: rgb(189 38 38);
  color: white;
}

.graph-blue {
  color: rgb(74 181 232);
}

.graph-red {
  color: rgb(252 104 104);
}

.graph-green {
  color: rgb(96 189 104);
}

.graph-yellow {
  color: rgb(222 207 63);
}

/* Media Queries */
@media screen and (min-width: 480px) {
  .no-pad {
    padding: 5px;
  }

  .list li:nth-of-type(odd) {
    background: none;
  }

  .list li:nth-of-type(even) {
    background: #d3d3d3;
  }

  .secondary-list li:nth-of-type(odd) {
    background: #d3d3d3;
  }

  .secondary-list li:nth-of-type(even) {
    background: none;
  }

  .list {
    padding: 0px;
  }

  .list li,
  .secondary-list li {
    padding: 5px;
  }

  #current-coverage li {
    margin-bottom: 0px;
  }
}

.data-table {
  margin-left: auto;
  margin-right: auto;
  border: solid rgb(127 29 29) 3px;
  border-radius: 50px;
}

.data-table-container {
  background-color: rgb(127 29 29);
  border-radius: 15px;
  padding: 15px;
}

.data-table-container-2 {
  background-color: rgb(127 29 29);
  border-radius: 15px;
  padding: 10px;
}

.data-table-header, .data-table-container-2 td {
  border: solid rgb(127 29 29) 1px;
  font-weight: 600;
  background-color: white;
  color: black;
}

.data-table, .data-table-container-2 td {
  border: solid rgb(127 29 29) 1px;
}

.title-1 {
  font-size: 0.75cm;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgb(127 29 29);
}

.text-input {
  display: block;
  margin-bottom: 20px;
  border-radius: 5px;
}

.list-input {
  display: inline;
  margin-top: 5px;
  width: 100%;
  border-radius: 5px;
}

.list-item {
  list-style-type: circle;
  margin-bottom: 10px;
}

.input-label {
  display: block;
  font-size: 0.5cm;
}

.checkbox-input {
  display: inline;
  border: 5px;
}

.checkbox-input-label {
  display: inline;
}

.button-1 {
  background-color: rgb(127 29 29);
  text-align: center;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  margin: 10px;
}

.button-1:hover {
  background-color: rgb(175, 26, 26);
}

.button-2 {
  background-color: rgb(5, 102, 0);
  text-align: center;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 10px;
  margin: 10px;
}

.button-2:hover {
  background-color: rgb(23, 146, 16);
}

.back-next-container {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.divider-1 {
  opacity: 1;
  color: white;
  border-bottom: dotted rgb(127 29 29) 5px;
  margin-top: 15px;
  margin-bottom: 25px;
  width: 300px;
}

